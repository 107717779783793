import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faList, faWheelchair, faFlag, faFeather, faEarthAfrica, faB, faBullhorn } from '@fortawesome/free-solid-svg-icons'

export const SidebarData = [
    { title: 'All', icon: <FontAwesomeIcon icon={faList} />, link: '/' },
    { title: 'BIPOC Resources', icon: <FontAwesomeIcon icon={faB} />, link: '/bipoc' },
    { title: 'Disability Resources', icon: <FontAwesomeIcon icon={faWheelchair} />, link: '/disability' },
    { title: 'Indigenous Resources', icon: <FontAwesomeIcon icon={faFeather} />, link: '/indigenous' },
    { title: 'International Resources', icon: <FontAwesomeIcon icon={faEarthAfrica} />, link: '/international' },
    { title: 'LGBTQ2S+', icon: <FontAwesomeIcon icon={faFlag} />, link: '/LGBTQ2S+' },
    { title: 'Mental Health', icon: <FontAwesomeIcon icon={faUser} />, link: '/mental-health' },
    { title: 'Sexualized Violence', icon: <FontAwesomeIcon icon={faBullhorn} />, link: '/sexualized-violence' },
]
