import styled from '@emotion/styled'
import { Paragraph18 } from '../typography'
import InspireLogo from '../../assets/images/inspire.png'

/* Wrapper that contains the logo header element */
const SectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    align-self: stretch;
`
/* Blue divider line */
const Line = styled.hr`
    border: none;
    height: 1px;
    width: 100%;
    background-color: ${(props) => props.theme.colour.lightBlue};
    flex-direction: row;
    margin: 0;
    &.mobile {
        visibility: hidden;
    }
`
/* Wrapper that contains the logo and title */
const InnerWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    padding: 0px;
    gap: 8px;
    color: white;
    text-align: center;
`
/* Wrapper with linear gradient that contains the StyledImg */
const ImgWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2px;
    width: 35px;
    height: 35px;
    background: linear-gradient(90deg, #1dc6e6 0%, #1de7b9 100%);
    border-radius: 10px;
`
/* Styled logo */
const StyledImg = styled.img`
    width: 31px;
    height: 31px;
    object-fit: cover;
`
/** Logo Header
 *  Hide blue lines for mobile by passing in 'mobile' for the style prop
 */
export const LogoHeader = (props: any) => (
    <SectionWrapper>
        <Line className={props.style} />
        <InnerWrapper>
            <ImgWrapper>
                <StyledImg src={InspireLogo}></StyledImg>
            </ImgWrapper>
            <Paragraph18>Engineering EDI Hub</Paragraph18>
        </InnerWrapper>
        <Line className={props.style} />
    </SectionWrapper>
)
