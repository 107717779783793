import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

/**
 * Navbar resource button
 */
export const ResourceButton = styled(Link)`
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 8px;
    gap: 8px;
    border-radius: 39px;
    border: none;
    background-color: transparent;
    font-family: ${(props) => props.theme.typography.fontFamily.regular};
    font-size: ${(props) => props.theme.typography.paragraph14.size};
    color: ${(props) => props.theme.colour.black};
    border: 1px solid ${(props) => props.theme.colour.background};
    &:active {
        color: #8d9bd4 !important;
        border: 1px solid #8d9bd4 !important;
        background: white !important;
        margin: 0;
    }
    &:hover {
        background: ${(props) => props.theme.colour.gradient.lilac};
        color: white;
    }

    &.active {
        background: ${(props) => props.theme.colour.gradient.lilac};
        color: white;
    }
    @media (min-width: ${(props) => props.theme.breakpoints.m}) {
        font-size: ${(props) => props.theme.typography.paragraph18.size};
    }
`
/**
 * Navbar report button
 */
export const ReportButton = styled(ResourceButton)`
    &:hover {
        background: ${(props) => props.theme.colour.gradient.turquoise};
        color: white;
    }
    &.active {
        background: ${(props) => props.theme.colour.gradient.turquoise};
        color: white;
    }
    &:active {
        color: #1dc6e6 !important;
        border: 1px solid #1dc6e6 !important;
    }
`
