import styled from '@emotion/styled'

export const StyledBackdrop = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 10;
`

export const Backdrop = (props: any) => <StyledBackdrop onClick={props.click} />
