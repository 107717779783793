import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import { ResourceCard } from '../components/atoms/resource-card'
import { HeaderText } from '../components/typography'
import { ResourceData } from '../data/resource-data'

const ResourceWrapper = styled.div`
    padding: 0px 16px 16px 16px;
    width: 100%;
    @media (min-width: ${(props) => props.theme.breakpoints.s}) {
        padding: 0px 24px 24px 24px;
    }
    @media (min-width: ${(props) => props.theme.breakpoints.m}) {
        padding: 0px 32px 32px 32px;
    }
`
const HeaderTitle = styled.div`
    margin-top: 16px;
    margin-bottom: 24px;
`
const ResourceCol = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`
const ColWrapper = styled.div`
    gap: 24px;
    display: grid;
    grid-template-columns: 1fr;
    @media (min-width: ${(props) => props.theme.breakpoints.s}) {
        grid-template-columns: 1fr 1fr;
    }
    @media (min-width: ${(props) => props.theme.breakpoints.l}) {
        grid-template-columns: 1fr 1fr 1fr;
    }
`

export const CardPage = (props: any) => {
    const desktopMinWidth = 1315
    let data = ResourceData

    // State that keeps track of the window width
    const [windowWidth, setwindowWidth] = useState(getwindowWidth())

    // Function that runs on window resize
    useEffect(() => {
        // Function called on window resize that sets the windowWidth state
        function handleWindowResize() {
            setwindowWidth(getwindowWidth())
        }

        window.addEventListener('resize', handleWindowResize)

        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    }, [windowWidth])

    // Function that returns the current window width
    function getwindowWidth() {
        return window.innerWidth
    }

    // Filters data by pageName
    if (props.pageName != 'All') {
        data = ResourceData.filter((val) => val.tag.toLowerCase().includes(props.pageName.toLowerCase()))
    }

    // Function that filters ResourceData using the keyword from the search bar
    const filteredData = data.filter((val) => {
        if (props.searchInput === '') {
            return val
        } else if (val.description.toLowerCase().includes(props.searchInput.toLowerCase())) {
            return val
        }
    })

    /**
     * Displays resource cards in three columns when the window width is desktop size
     * The filtered data is sliced into thirds and mapped into cards in their respective columns
     */
    const desktopMap = (
        <ColWrapper>
            <ResourceCol id='1/3'>
                {filteredData.slice(0, Math.ceil(filteredData.length * (1 / 3))).map((val, key) => (
                    <ResourceCard key={key} logo={val.logo} resourceName={val.resourceName} description={val.description} website={val.website} />
                ))}
            </ResourceCol>
            <ResourceCol id='2/3'>
                {filteredData.slice(Math.ceil(filteredData.length * (1 / 3)), Math.ceil(filteredData.length * (2 / 3))).map((val, key) => (
                    <ResourceCard key={key} logo={val.logo} resourceName={val.resourceName} description={val.description} website={val.website} />
                ))}
            </ResourceCol>
            <ResourceCol id='3/3'>
                {filteredData.slice(Math.ceil(filteredData.length * (2 / 3)), Math.ceil(filteredData.length)).map((val, key) => (
                    <ResourceCard key={key} logo={val.logo} resourceName={val.resourceName} description={val.description} website={val.website} />
                ))}
            </ResourceCol>
        </ColWrapper>
    )

    /**
     * Displays resource cards in 2 columns when window width is tablet size
     * Will become 1 column when the window width is mobile size
     */
    const tabletMap = (
        <ColWrapper>
            <ResourceCol>
                {filteredData.slice(0, Math.ceil(filteredData.length * (1 / 2))).map((val, key) => (
                    <ResourceCard key={key} logo={val.logo} resourceName={val.resourceName} description={val.description} website={val.website} />
                ))}
            </ResourceCol>
            <ResourceCol>
                {filteredData.slice(Math.ceil(filteredData.length * (1 / 2)), filteredData.length).map((val, key) => (
                    <ResourceCard key={key} logo={val.logo} resourceName={val.resourceName} description={val.description} website={val.website} />
                ))}
            </ResourceCol>
        </ColWrapper>
    )

    return (
        <ResourceWrapper>
            <HeaderTitle>
                <HeaderText className='resourceStyling'>{props.pageName}</HeaderText>
            </HeaderTitle>
            {windowWidth >= desktopMinWidth ? desktopMap : tabletMap}
        </ResourceWrapper>
    )
}
