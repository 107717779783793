import gem from '../assets/images/gem.png'
import avp from '../assets/images/AVP.png'
import uvic from '../assets/images/uvic-logo.jpg'
import psc from '../assets/images/peer-support-center.jpg'
import uvicPride from '../assets/images/uvic-pride.jpg'
import ssd from '../assets/images/uvic-ssd.jpg'
import socc from '../assets/images/socc.jpg'
import nativeStudentsUnion from '../assets/images/native-students-union.png'
import IACE from '../assets/images/inac-logo.jpg'

/**
 * Resource List: Contains all the data for the resource cards
 *
 * resourceName: Place the resource name within a string
 *
 * logo:
 *  1. Add image to the assets/images file
 *  2. Import the image into the resource-data file at the top of the page. Example: import IACE from '../assets/images/inac-logo.jpg'
 *  3. Reference the import name as the logo's value.
 *
 * website: Place the website address within a string
 *
 * tags:
 *  1. To add a resource to a subpage simply write the name of the page of where you want it displayed in a string.
 *  2. If there are multiple pages you want to display a resource on, separate the page names by a space.
 *  Ex. tag: 'LGBTQ2S+ Mental Health'
 *
 * Empty resource: (Copy and paste into the resources array, then fill out)
    {
        resourceName: '',
        logo: ,
        description:
            "",
        website: '',
        tag: '',
    },
 */

const resources = [
    {
        resourceName: 'Equity & Human Rights Office',
        logo: uvic,
        description:
            'Equity and Human Rights is a resource for all UVic community members, providing education, information, assistance and advice in aid of building and supporting an inclusive campus. We believe that our campus should represent the diversity of our larger communities and that every person--student staff and faculty--should be treated equitably. To this end we work closely across all units to build equity and diversity through education, prevention and structural initiatives. Finally, when issues and concerns arise, we are here to assist community members and help guide them through the range of available resolution options.',
        website: 'https://www.uvic.ca/equity/index.php',
        tag: 'Sexualized Violence',
    },
    {
        resourceName: 'Anti-Violence Project',
        logo: avp,
        description:
            'Free, confidential, non-judgmental support, resources, and referrals for people of all genders. Free workshops and trainings on many topics such as: communication skills, consent, healthy relationships, healthy sexuality, intervening with your peer group… Financial, organizational, and volunteer support for community prevention and awareness events. Resource Library offers a variety of print materials on issues related to sexualized violence for research or personal use Free safer sex supplies. Services open to community members',
        website: 'https://www.antiviolenceproject.org/',
        tag: 'LGBTQ2S+ Mental Health',
    },
    {
        resourceName: 'Peer Support Centre',
        logo: psc,
        description:
            'A safer space created to be an inclusive and comfortable space for students from all walks of life. Whether you are struggling with or have questions regarding mental health, are concerned for a friend, or need help accessing resources on or off campus, you can seek a first-point-of-contact at the Peer Support Centre. Our trained student volunteers are here to listen, offering non-judgmental, empathetic and confidential support while helping fellow students navigate next-step resources and student life at UVic.',
        website: 'https://uvss.ca/peer-support-centre/',
        tag: 'Mental Health',
    },
    {
        resourceName: 'Gender Empowerment Center',
        logo: gem,
        description:
            'The Gender Empowerment Centre is an inclusive space, focused on the needs of self-identified women, non-binary, trans and gender non-conforming folks to organize, network, access resources, attend workshops and events, study, and relax. Our space is open to both University of Victoria students and community members.',
        website: 'https://genderempowermentcentre.ca/',
        tag: 'LGBTQ2S+',
    },
    {
        resourceName: 'UVic Pride',
        logo: uvicPride,
        description:
            'The University of Victoria Pride Collective works to provide a safer space for individuals within and outside of the queer and trans communities, both at the University of Victoria and in the broader communities. The UVic Pride Collective aims to alleviate all forms of oppression and to address a lack of available safer space, community and education regarding queer and trans issues, needs and experiences. The University of Victoria Pride Collective acts as an organisation that facilitates community building through various activities and advocates for issues related to sexual orientation and expression and/or gender identity and expression.',
        website: 'https://www.prideuvic.com/',
        tag: 'LGBTQ2S+',
    },
    {
        resourceName: 'Society for Students with a Disability',
        logo: ssd,
        description:
            "The Society for Students with a Disability (SSD) is a post-secondary student advocacy group under the University of Victoria Students' Union which promotes the full participation and inclusion of students who self-identify as having one or more disabilities at the University of Victoria. The SSD aims to support students who self-identify as having one or more disabilities; in particular, we support students to reduce barriers in the social, emotional, and educational domains of student life.",
        website: 'https://uvicssd.ca/',
        tag: 'Disability Resources',
    },
    {
        resourceName: 'Students of Colour Collective',
        logo: socc,
        description:
            'Students of colour at UVic: we are here for YOU! We are a Collective of self-identified people of colour at the University of Victoria. Anyone identifying as BIPOC (Black, Indigenous and Person of Colour) is welcome to use our lounge and access our resources. SOCC exists to create space that fosters community building, and facilitates networking and gatherings for self-identified BIPOC folks. Our work centres on art, awareness, education, action, advocacy, and support. Every student of colour is welcome to get in touch and find out how to get involved.',
        website: 'https://soccollective.com/',
        tag: 'BIPOC Resources',
    },
    {
        resourceName: 'Growth and Solidarity',
        logo: uvic,
        description:
            'Growth and Solidarity is a resource hub and research community that aims to strengthen Asian mental health across Canada. Our goal is to promote healing and growth, racial equity, and cultivate solidarity within the Asian community and other ethno-racial communities in Canada. We are involved in research, mental health promotion, and the collection and development of mental health resources. As a research community, we host information about culturally relevant research projects and build collaborations with the community and other researchers that support the collective goal of strengthening Asian mental health.',
        website: 'https://www.growthandsolidarity.ca/landing/',
        tag: 'BIPOC Resources',
    },
    {
        resourceName: 'Native Students Union',
        logo: nativeStudentsUnion,
        description:
            'The Native Students Union is an organization run for and by Indigenous students at UVic. Since circa 1969, Indigenous students have been coming together to form community, support each other, advocate for Indigenous rights and wellbeing, and just exist together at the institution. The NSU runs events and programs, manages a student space with services, and advocates for Indigenous student success and wellness at UVic. The NSU Council leads the NSU in running and developing these services. ',
        website: 'https://www.uvicnsu.ca/home',
        tag: 'Indigenous Resources',
    },
    {
        resourceName: 'Student Wellness Centre',
        logo: uvic,
        description:
            "At the Student Wellness Centre our team of practitioners offers a variety of services to support students' mental, physical and spiritual health. You can make a same-day or pre-booked appointment with a counsellor, nurse, physician, or spiritual care provider.",
        website: 'https://www.uvic.ca/student-wellness/',
        tag: 'Mental Health',
    },
    {
        resourceName: 'SupportConnect',
        logo: uvic,
        description:
            'SupportConnect is a free, confidential mental health support service for UVic students. Get connected with qualified counsellors, consultants and life coaches anytime, anywhere. SupportConnect offers short-term solution-focused counselling with the same counsellor. You and your counsellor will work together through a set of sessions to assist you in moving forward. SupportConnect is available 24/7 by phone and online. They also offer video and in-person options. You can reach out for any reason, big or small.',
        website: 'https://www.uvic.ca/student-wellness/wellness-resources/supportconnect/index.php',
        tag: 'Mental Health',
    },
    {
        resourceName: 'Multifaith Centre',
        logo: uvic,
        description:
            "The Multifaith Centre is next to the beautiful Finnerty Gardens. It's a peaceful place to mark important life events, connect with peers and spiritual leaders or just take refuge from your day. At the Multifaith Centre, we take your spiritual health seriously. We provide one-on-one spiritual care as well as culturally diverse free weekly activities, events and programs.",
        website: 'https://www.uvic.ca/campus/multifaith-centre/index.php',
        tag: 'Mental Health',
    },
    {
        resourceName: 'UVic EDI Policies',
        logo: uvic,
        description: 'A list of policies that support equity, diversity and inclusion (EDI) at UVic.',
        website: 'https://www.uvic.ca/equity/about/policies/index.php',
        tag: 'UVic EDI Policies',
    },
    {
        resourceName: 'First Peoples House',
        logo: IACE,
        description:
            'The First Peoples House (FPH) is a social, cultural and academic centre for Indigenous students at UVic and serves as a safe and welcoming place that encourages the building of community. We encourage you to come in and be part of creating a supportive space for Indigenous students on campus. The First Peoples House is a home-away-from-home for Indigenous students and provides a safe space for learning, sharing and community building.',
        website: 'https://www.uvic.ca/services/indigenous/house/',
        tag: 'Indigenous Resources',
    },
    {
        resourceName: 'Indigenous Counselling',
        logo: IACE,
        description:
            'Indigenous counselling offers a safe and caring place that honours Indigenous knowledge, experience and healing. The Indigenous Counsellor treats mental health and life issues from Indigenous perspectives to relate to the diverse cultural backgrounds of Indigenous students. Treatment may also address the long-lasting intergenerational effects of historical wrongdoings against Indigenous peoples. We provide both individual and group counselling and discussions that treat the heart, body, mind and spirit.',
        website: 'https://www.uvic.ca/services/indigenous/students/counselling/index.php',
        tag: 'Indigenous Resources',
    },
    {
        resourceName: 'Elders in Residence',
        logo: IACE,
        description:
            'The Elders in Residence provide spiritual support, encouragement and guidance for Indigenous students at UVic. Elders are the caretakers of cultural wisdom. They are pivotal in protecting the Indigenous ways of knowing and being that have been passed down to them for generations from their ancestors. The cultural wisdom of Elders defines and informs the manner in which Indigenous people live in the modern world. Elders know it is their responsibility to share their guiding wisdom with students, staff, faculty and the broader community.',
        website: 'https://www.uvic.ca/services/indigenous/students/elders/index.php',
        tag: 'Indigenous Resources',
    },
    {
        resourceName: 'International Centre for Students',
        logo: uvic,
        description:
            'The International Centre for Students (ICS) is a centralized resource providing international services, information and programs for undergraduate and graduate students from pre-arrival to degree completion.',
        website: 'https://www.uvic.ca/international/home/contact/iss/index.php',
        tag: 'International Resources',
    },
    {
        resourceName: 'Office of Global Engagement',
        logo: uvic,
        description:
            "The Office of Global Engagement develops and promotes global partnerships that advance UVic's strategic goals for internationalization, which includes providing support to UVic faculty and staff in furthering their international activities and coordinating international partnerships, projects and programs that enhance UVic's diverse intercultural and global environment.",
        website: 'https://www.uvic.ca/international/home/contact/global-engagement/index.php',
        tag: 'International Resources',
    },
    {
        resourceName: 'Centre for Accessible Learning',
        logo: uvic,
        description:
            'The Centre for Accessible Learning (CAL) works with faculty and students to promote educational accessibility for students with disabilities and chronic health conditions. We support students in achieving their academic goals by reducing educational barriers through accessibility and programming, and providing resources for instructors to create an inclusive and accessible learning environment',
        website: 'https://www.uvic.ca/accessible-learning/index.php',
        tag: 'Disability Resourcess',
    },
    {
        resourceName: 'Indigenous Academic & Community Engagement',
        logo: IACE,
        description:
            "IACE's primary responsibility is supporting Indigenous students, but the office has also taken on many other roles, such as: Managing the First Peoples House, building and supporting Indigenous partnerships, and ensuring that Indigenous content and ways of knowing are included in UVic curriculum and events",
        website: 'https://www.uvic.ca/services/indigenous/index.php',
        tag: 'Indigenous Resources',
    },
    {
        resourceName: 'Learning Assistance Program',
        logo: uvic,
        description:
            'The UVic Learning Assistance Program connects students with specialized tutors and learning strategists for one-to-one, personalized learning support. Our passionate team of graduate student tutors and learning strategists love working with, and learning from, students from all walks of life and academic fields.',
        website: 'https://onlineacademiccommunity.uvic.ca/lap/',
        tag: 'Disability Resources',
    },
]

// Function that sorts the resources array
export const ResourceData = resources.sort((a, b) => a.resourceName.localeCompare(b.resourceName))
