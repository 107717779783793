import { Routes, Route } from 'react-router-dom'
import { useState } from 'react'
import { PageElement } from '../components/page-element'
import { pages } from '../data/page-data'

export const Main = () => {
    const [sideMenuOpen, setSideMenuOpen] = useState(false)

    // Function that sets the state of the sideMenuOpen to the opposite of it's current boolean value
    const sideMenuHandler = () => setSideMenuOpen(!sideMenuOpen)
    // Function that sets the state of the sideMenuOpen to false
    const backdropClickHandler = () => setSideMenuOpen(false)

    return (
        <Routes>
            {pages.map((val, key) => (
                <Route key={key} path={val.path} element={<PageElement page={val.page} sideMenuOpen={sideMenuOpen} sideMenuHandler={sideMenuHandler} backdropClickHandler={backdropClickHandler} />} />
            ))}
        </Routes>
    )
}
