import styled from '@emotion/styled'

export const SubmitButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 24px;
    background-color: #04a9f5;
    border-radius: 5px;
    width: fit-content;
    border: none;
    transition: all 0.1s ease-in-out;
    color: white;
    &: hover {
        background-color: #007bff;
    }
    &: active {
        border: 1px solid #04a9f5;
        border-radius: 5px;
        background-color: ${(props) => props.theme.colour.background};
        color: #007bff;
    }
`
