import styled from '@emotion/styled'
import { defaultTheme } from './theme/theme'
import { ThemeProvider } from '@emotion/react'
import { Main } from './pages/main'

function App() {
    return (
        <ThemeProvider theme={defaultTheme}>
            <Main />
        </ThemeProvider>
    )
}

export default App
