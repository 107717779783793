import styled from '@emotion/styled'
import { Backdrop } from '../components/atoms/backdrop'
import { MobileNav } from '../components/atoms/mobile-nav'
import { Sidebar } from './sidebar'
import { Navbar } from './navbar'
import { useState } from 'react'
import { CardPage } from '../pages/card-page'
import { useLocation } from 'react-router-dom'
import { Feedback } from '../pages/feedback'

// Wrapper that contains all elements
const MainWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.colour.background};
`
// Wrapper that contains everything, but the sidebar
const LeftWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.colour.background};
`
/**
 * Page Element:
 * Displays all the pages of the web application
 */
export const PageElement = (props: any) => {
    const location = useLocation()
    const [searchTerm, setSearchTerm] = useState('')
    return (
        <MainWrapper>
            <Sidebar type='desktop' />
            {props.sideMenuOpen && (
                <>
                    <Sidebar type='mobile' click={props.backdropClickHandler} /> <Backdrop click={props.backdropClickHandler} />
                </>
            )}
            <LeftWrapper>
                <MobileNav drawerClickHandler={props.sideMenuHandler} menuOpen={props.sideMenuOpen} />
                <Navbar search={setSearchTerm} />
                {location.pathname === '/feedback' ? <Feedback /> : <CardPage searchInput={searchTerm} pageName={props.page} />}
            </LeftWrapper>
        </MainWrapper>
    )
}
