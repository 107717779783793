import styled from '@emotion/styled'
import { ReportButton, ResourceButton } from './atoms/nav-buttons'
import { useLocation } from 'react-router-dom'
import { SearchBar } from './atoms/search-bar'

const NavWrapper = styled.div`
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 16px;
    gap: 8px;
    top: 0;
    background-color: ${(props) => props.theme.colour.background};
    @media (min-width: ${(props) => props.theme.breakpoints.s}) {
        padding: 20px 24px;
    }
    @media (min-width: ${(props) => props.theme.breakpoints.m}) {
        padding: 20px 32px;
    }
`
const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 16px;
    @media (min-width: ${(props) => props.theme.breakpoints.s}) {
        gap: 24px;
    }
`

export const Navbar = (props: any) => {
    const location = useLocation()
    return (
        <NavWrapper>
            <ButtonWrapper>
                <ResourceButton to='/' className={location.pathname != '/feedback' ? 'active' : ''}>
                    Resource Hub
                </ResourceButton>
                <ReportButton to='/feedback' className={location.pathname == '/feedback' ? 'active' : ''}>
                    EDI Feedback
                </ReportButton>
            </ButtonWrapper>
            {location.pathname != '/feedback' && <SearchBar search={props.search} />}
        </NavWrapper>
    )
}
