import styled from '@emotion/styled'
import { useForm } from 'react-hook-form'
import { SubmitButton } from '../components/atoms/submit-button'
import { useRef, useState } from 'react'
import { HeaderText } from '../components/typography'
import emailjs from '@emailjs/browser'
import { Tooltip } from '../components/atoms/tooltip'

const ResourceWrapper = styled.form`
    display: flex;
    flex-direction: column;
    padding: 0px;
    gap: 24px;
    padding: 0px 16px 16px 16px;
    @media (min-width: ${(props) => props.theme.breakpoints.s}) {
        padding: 0px 24px 24px 24px;
    }
    @media (min-width: ${(props) => props.theme.breakpoints.m}) {
        padding: 0px 32px 32px 32px;
    }
`
const CommentCard = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 24px;
    gap: 24px;
    background-color: ${(props) => props.theme.colour.cardBackground};
    box-shadow: 0px 4px 6px rgba(65, 76, 78, 0.04);
`
const PrivacyCard = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 24px;
    gap: 24px;
    background-color: ${(props) => props.theme.colour.cardBackground};
    box-shadow: 0px 4px 6px rgba(65, 76, 78, 0.04);
`
const CommentInput = styled.textarea`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px 24px;
    background-color: #f4f7fa;
    border: 1px solid #ced4da;
    border-radius: 2px;
    align-self: stretch;
    outline: none;
    height: 120px;
    font-family: ${(props) => props.theme.typography.fontFamily.regular};
    font-size: ${(props) => props.theme.typography.paragraph14.size};
    @media (min-width: ${(props) => props.theme.breakpoints.s}) {
        font-size: ${(props) => props.theme.typography.paragraph16.size};
    }
    &:focus {
        border-color: #80bdff;
        box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
    }
`
const PersonalInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 36px;
    align-self: stretch;
    @media (min-width: ${(props) => props.theme.breakpoints.s}) {
        flex-direction: row;
    }
    &.hide {
        display: none;
    }
`
const PersonalInfoCard = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center !important;
    padding: 16px;
    gap: 24px;
    background: ${(props) => props.theme.colour.cardBackground};
    box-shadow: 0px 4px 6px rgba(65, 76, 78, 0.04);
    align-self: stretch;
    @media (min-width: ${(props) => props.theme.breakpoints.s}) {
        flex-grow: 1;
    }
`
const TextInputWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    gap: 24px;
`
const PersonalInfoErrorWrapper = styled.div`
    display: flex;
    gap: 16px;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    @media (max-width: 480px) {
        flex-direction: column;
        align-items: stretch;
    }
`
const PersonalInfoInput = styled.input`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px 24px;
    background: #f4f7fa;
    border: 1px solid #ced4da;
    border-radius: 2px;
    flex-grow: 1;
    font-family: ${(props) => props.theme.typography.fontFamily.regular};
    font-size: ${(props) => props.theme.typography.paragraph14.size};
    outline: none;
    @media (min-width: ${(props) => props.theme.breakpoints.s}) {
        font-size: ${(props) => props.theme.typography.paragraph16.size};
    }
    &:focus {
        border-color: #80bdff;
        box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
    }
`
const CheckboxWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 0px;
    gap: 32px;
    @media (min-width: 536px) {
        flex-direction: row;
    }
`
const OptionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
`
const CommentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    align-self: stretch;
    &.privacyMobile {
        @media (max-width: 825px) {
            flex-direction: column;
        }
    }
`
const ErrorText = styled.div`
    color: #f4732b;
    font-family: ${(props) => props.theme.typography.fontFamily.semiBold};
    text-transform: uppercase;
    font-size: 12px;
    text-align: left;
`
const FormText = styled.div`
    font-family: ${(props) => props.theme.typography.fontFamily.regular};
    font-size: ${(props) => props.theme.typography.paragraph14.size};
    // text-align: center;
    @media (min-width: ${(props) => props.theme.breakpoints.s}) {
        font-size: ${(props) => props.theme.typography.paragraph16.size};
        // text-align: left;
    }
    &.thank-you {
        max-width: 500px;
    }
    &.header {
        text-align: center;
        @media (min-width: ${(props) => props.theme.breakpoints.xs}) {
            text-align: left;
        }
    }
`
const SemiBoldTitle = styled.div`
    color: ${(props) => props.theme.colour.button.blue};
    font-family: ${(props) => props.theme.typography.fontFamily.semiBold};
    text-transform: uppercase;
    font-size: ${(props) => props.theme.typography.paragraph14.size};
    text-align: center;
    @media (min-width: ${(props) => props.theme.breakpoints.s}) {
        font-size: ${(props) => props.theme.typography.paragraph16.size};
        text-align: left;
    }
`
const FeedbackTitle = styled.div`
    font-family: ${(props) => props.theme.typography.fontFamily.regular};
    font-size: 36px;
    text-align: center;
    @media (min-width: ${(props) => props.theme.breakpoints.xs}) {
        text-align: left;
    }
    @media (min-width: ${(props) => props.theme.breakpoints.s}) {
        font-size: ${(props) => props.theme.typography.header44.size};
    }
`

const ThankYouWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 48px;
    gap: 16px;
    height: 500px;
    text-align: center;
`

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`

export const Feedback = () => {
    // State that keeps track of whether the anonymous radio button has beed checked
    const [anonymous, setIsAnonymous] = useState(false)
    const isAnonymous = () => setIsAnonymous(true)
    const notAnonymous = () => setIsAnonymous(false)

    // State that keeps track of the form being submitted
    const [isSubmitted, setIsSubmitted] = useState(false)
    // State that keeps track of errors thrown by sending a form to emailjs
    const [emailjsError, setEmailjsError] = useState(false)

    // Ref that references the form tag
    const form = useRef<any>()

    //Function that scrolls the page to the top
    const goToTop = () => {
        window.scrollTo({
            top: 0,
        })
    }

    const {
        register, // method to register form input
        handleSubmit, // method to handle form submission
        formState: { errors }, // contains error information
    } = useForm({
        mode: 'onSubmit',
    })

    // Function that submits the data from the  and is called when the form submit button is clicked
    const submitData = (data: any) => {
        if (data.privacy === 'anonymous') {
            emailjs.sendForm('inspire-edi-feedback', 'anonymous-edi-template', form.current, 'cjiAV2T12swI-qG8f').then(
                (result) => {
                    console.log(result.text)
                    setIsSubmitted(true)
                    goToTop()
                },
                (error) => {
                    console.log(error.text)
                    setEmailjsError(true)
                }
            )
        } else {
            emailjs.sendForm('inspire-edi-feedback', 'edi-committee-template', form.current, 'cjiAV2T12swI-qG8f').then(
                (result) => {
                    console.log(result.text)
                    setIsSubmitted(true)
                    goToTop()
                },
                (error) => {
                    console.log(error.text)
                    setEmailjsError(true)
                }
            )
        }
    }

    // If isSubmitted is true render the thank you page
    if (isSubmitted == true) {
        return (
            <ThankYouWrapper>
                <HeaderText>Thank you!</HeaderText>
                <FormText className='thank-you'>We appreciate your feedback. If you provided your email, a confirmation will be sent to you shortly.</FormText>
            </ThankYouWrapper>
        )
    }

    return (
        <ResourceWrapper ref={form} onSubmit={handleSubmit(submitData)}>
            <TitleWrapper>
                <FeedbackTitle>Share Your Feedback</FeedbackTitle>
                {/* <SemiBoldTitle>Engineering and Computer Science EDI Committee</SemiBoldTitle> */}
                <FormText className='header'>
                    We appreciate and welcome your comments on equity, diversity and inclusion within the Faculty of Engineering and Computer Science. Please complete the form below and let us know
                    how we are doing.
                </FormText>
            </TitleWrapper>

            <CommentCard>
                <CommentWrapper>
                    <FormText>Comment *</FormText>
                    {errors.comment && <ErrorText>A comment is required</ErrorText>}
                </CommentWrapper>
                <CommentInput {...register('comment', { required: true })} />
            </CommentCard>

            <PrivacyCard>
                <CommentWrapper className='privacyMobile'>
                    <FormText>Would you like to make your comments anonymous or confidential? *</FormText>
                    {errors.privacy && <ErrorText>A privacy choice is required</ErrorText>}
                </CommentWrapper>
                <CheckboxWrapper>
                    <OptionWrapper>
                        <input type='radio' value='anonymous' {...register('privacy', { required: true })} onClick={isAnonymous} />
                        <FormText>Anonymous</FormText>
                        <Tooltip message={'Your name and email are not required'} />
                    </OptionWrapper>
                    <OptionWrapper>
                        <input type='radio' value='confidential' {...register('privacy', { required: true })} onClick={notAnonymous} />
                        <FormText>Confidential</FormText>
                        <Tooltip message={'Your name and email will only be shared with designated EDI representatives appointed by the Dean of the Engineering and Computer Science Faculty'} />
                    </OptionWrapper>
                    <OptionWrapper>
                        <input type='radio' value='none' {...register('privacy', { required: true })} onClick={notAnonymous} />
                        <FormText>Neither</FormText>
                    </OptionWrapper>
                </CheckboxWrapper>
            </PrivacyCard>

            <PersonalInfoWrapper className={anonymous ? 'hide' : ''}>
                <PersonalInfoCard>
                    <FormText>Name:</FormText>
                    <PersonalInfoInput {...register('name')} />
                </PersonalInfoCard>
                <PersonalInfoCard>
                    <PersonalInfoErrorWrapper>
                        <TextInputWrapper>
                            <FormText>Email:</FormText>
                            <PersonalInfoInput {...register('email', { pattern: /\S+@\S+\.\S+/ })} />
                        </TextInputWrapper>
                        {errors.email && <ErrorText>Invaild Email</ErrorText>}
                    </PersonalInfoErrorWrapper>
                </PersonalInfoCard>
            </PersonalInfoWrapper>
            {emailjsError && <ErrorText>Error sending feedback. Please try again later.</ErrorText>}
            <SubmitButton>
                <FormText>Submit</FormText>
            </SubmitButton>
        </ResourceWrapper>
    )
}
