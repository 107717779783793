import styled from '@emotion/styled'
import { SidebarData } from '../data/sidebar-data'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { Paragraph14 } from './typography'
import { LogoHeader } from './atoms/logo-header'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDownLong } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from 'react'

const SideBarWrapper = styled.div`
    display: none;
    min-width: 264px;
    max-width: 264px;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 24px;
    gap: 24px;
    box-shadow: 1px 0 20px 0 #3f4d67;
    background-color: ${(props) => props.theme.colour.slate};
    z-index: 20;
    &.desktop {
        @media (min-width: ${(props) => props.theme.breakpoints.m}) {
            display: flex;
        }
    }
    &.mobile {
        display: flex;
        top: 0;
        left: 0;
        position: fixed;
        overflow-y: scroll;
        @media (min-width: ${(props) => props.theme.breakpoints.m}) {
            display: none;
        }
    }
`
const SideBarList = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    margin: 0px;
    align-self: stretch;
`
const ListItem = styled.li`
    display: flex;
    height: 44px;
    list-style-type: none;
    flex-direction: row;
    color: white;
    justify-content: left;
    align-items: center;
    gap: 8px;
    &:hover {
        color: ${(props) => props.theme.colour.lightBlue};
    }
    &.active {
        color: ${(props) => props.theme.colour.lightBlue};
    }
`
const Icon = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 24px;
    height: 24px;
`
const SidebarLink = styled(Link)`
    text-decoration: none;
    font-family: ${(props) => props.theme.typography.fontFamily.regular};
    font-size: ${(props) => props.theme.typography.paragraph14.size};
    align-self: stretch;
`
/* Feedback sidebar styles */

const ListContainer = styled.div`
    display: flex;
    align-self: stretch;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 36px;
`
const ListWrapper = styled.div`
    display: flex;
    align-self: stretch;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 16px;
    color: ${(props) => props.theme.colour.text.slateGrey};
    text-align: center;
`
const FeedbackTitle = styled.div`
    font-family: ${(props) => props.theme.typography.fontFamily.semiBold};
    font-size: ${(props) => props.theme.typography.paragraph14.size};
    color: white;
    text-transform: uppercase;
`
const Line = styled.hr`
    border: none;
    height: 1px;
    width: 100%;
    background-color: ${(props) => props.theme.colour.lightBlue};
    flex-direction: row;
    margin: 0;
    padding: none;
    &.mobile {
        visibility: hidden;
    }
`

export const Sidebar = (props: any) => {
    // variable that holds the current page location
    const location = useLocation()

    // State that keeps track of the window height
    const [windowHeight, setWindowHeight] = useState(getwindowHeight())

    // function that returns the window height
    function getwindowHeight() {
        return window.innerHeight
    }

    // Function that runs on window resize
    useEffect(() => {
        // Function called on window resize that sets the windowHeight state
        function handleWindowResize() {
            setWindowHeight(getwindowHeight())
        }

        window.addEventListener('resize', handleWindowResize)

        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    }, [windowHeight])

    // Sidebar for the Resource Hub
    const sidebarList = (
        <SideBarList style={{ minHeight: windowHeight }}>
            {SidebarData.map((val, key) => (
                <SidebarLink to={val.link} key={key}>
                    <ListItem onClick={props.click} className={location.pathname == val.link ? 'active' : ''}>
                        <Icon>{val.icon}</Icon>
                        <Paragraph14>{val.title}</Paragraph14>
                    </ListItem>
                </SidebarLink>
            ))}
        </SideBarList>
    )

    // Sidebar that is displayed only on the feedback page
    const feedbackSidebar = (
        <ListContainer style={{ minHeight: windowHeight }}>
            <ListWrapper>
                <div>
                    <FeedbackTitle>Feedback Process</FeedbackTitle>
                    <Line />
                </div>
                <Paragraph14>Submit feedback</Paragraph14>
                <FontAwesomeIcon icon={faArrowDownLong} />
                <Paragraph14>Feedback is received by the head of the EDI committee</Paragraph14>
                <FontAwesomeIcon icon={faArrowDownLong} />
                <Paragraph14>Feedback is discussed at the next EDI committee meeting</Paragraph14>
                <FontAwesomeIcon icon={faArrowDownLong} />
                <Paragraph14>A follow up email is sent to the respondent</Paragraph14>
            </ListWrapper>
        </ListContainer>
    )

    return (
        <SideBarWrapper className={props.type}>
            <LogoHeader />
            {location.pathname === '/feedback' ? feedbackSidebar : sidebarList}
        </SideBarWrapper>
    )
}
