import { Theme } from '@emotion/react'

const defaultTheme: Theme = {
    colour: {
        slate: '#3f4d67',
        lightBlue: '#22b2d5',
        background: '#f4f7fa',
        cardBackground: '#fff',
        navbarBorder: '#f1f1f1',
        inputBorder: '#ced4da',
        black: '#000',
        darkGray: '#333',
        text: {
            slateGrey: '#a9b7d0',
            black: '#111',
            grey: '#888',
            white: '#fff',
            form: '#495057',
        },
        button: {
            blue: '#04a9f5',
            grey: '#748892',
        },
        gradient: {
            turquoise: 'linear-gradient(90deg, #1dc6e6 0%, #1de7b9 100%);',
            lilac: 'linear-gradient(90deg, #9d8ed4 0%, #8d9bd4 100%)',
        },
    },
    typography: {
        fontFamily: {
            regular: 'OpenSans-Regular',
            semiBold: 'OpenSans-SemiBold',
        },
        header44: {
            size: '44px',
        },
        paragraph20: {
            size: '20px',
        },
        paragraph18: {
            size: '18px',
        },
        paragraph16: {
            size: '16px',
        },
        paragraph14: {
            size: '14px',
        },
        sidebarLabel: {
            size: '10px',
        },
    },
    breakpoints: {
        xs: '425px',
        s: '768px',
        m: '1024px',
        l: '1315px',
    },
}

export { defaultTheme }
