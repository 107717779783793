import styled from '@emotion/styled'
import { LogoHeader } from './logo-header'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark, faBars } from '@fortawesome/free-solid-svg-icons'

const MobileNavContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    gap: 32px;
    background-color: ${(props) => props.theme.colour.slate};
    @media (min-width: ${(props) => props.theme.breakpoints.m}) {
        display: none;
        padding: 12px 24px;
    }
    @media (min-width: ${(props) => props.theme.breakpoints.s}) {
        padding: 12px 24px;
    }
`
const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 32px;
    height: 32px;
    color: ${(props) => props.theme.colour.lightBlue};
`

/**
 * Mobile Navbar that only displays at for viewports smaller than 768px
 */
export const MobileNav = (props: any) => (
    <MobileNavContainer id='title'>
        <LogoHeader style='mobile' />
        <IconWrapper onClick={props.drawerClickHandler}>
            <FontAwesomeIcon icon={props.menuOpen ? faXmark : faBars} />
        </IconWrapper>
    </MobileNavContainer>
)
