/* An array that contains page names and their paths */
export const pages = [
    { page: 'All', path: '/' },
    { page: 'BIPOC Resources', path: '/bipoc' },
    { page: 'Disability Resources', path: '/disability' },
    { page: 'Indigenous Resources', path: '/indigenous' },
    { page: 'International Resources', path: '/international' },
    { page: 'LGBTQ2S+', path: '/LGBTQ2S+' },
    { page: 'Mental Health', path: '/mental-health' },
    { page: 'Sexualized Violence', path: '/sexualized-violence' },
    { page: 'Feedback', path: '/feedback' },
]
