import styled from '@emotion/styled'

export const Header44 = styled.span`
    font-family: ${(props) => props.theme.typography.fontFamily.regular};
    font-size: ${(props) => props.theme.typography.header44.size};
`
export const Paragraph20 = styled.span`
    font-family: ${(props) => props.theme.typography.fontFamily.regular};
    font-size: ${(props) => props.theme.typography.paragraph20.size};
`
export const Paragraph18 = styled.span`
    font-family: ${(props) => props.theme.typography.fontFamily.regular};
    font-size: ${(props) => props.theme.typography.paragraph18.size};
`
export const Paragraph16 = styled.span`
    font-family: ${(props) => props.theme.typography.fontFamily.regular};
    font-size: ${(props) => props.theme.typography.paragraph16.size};
`
export const Paragraph14 = styled.span`
    font-family: ${(props) => props.theme.typography.fontFamily.regular};
    font-size: ${(props) => props.theme.typography.paragraph14.size};
`
export const HeaderText = styled.span`
    font-family: ${(props) => props.theme.typography.fontFamily.regular};
    font-size: 36px;
    @media (min-width: ${(props) => props.theme.breakpoints.s}) {
        font-size: ${(props) => props.theme.typography.header44.size};
    }
`
