import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faXmark } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useRef, useState } from 'react'

const SearchContainer = styled.div`
    display: flex;
    position: static;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    &.open {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 15;
        padding: 0px 16px;
        background-color: ${(props) => props.theme.colour.cardBackground};
        @media (min-width: ${(props) => props.theme.breakpoints.s}) {
            padding: 20px 24px;
        }
        @media (min-width: ${(props) => props.theme.breakpoints.m}) {
            padding: 20px 32px;
        }
    }
`
const SearchWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 16px;
`
const SearchInput = styled.input`
    border: none;
    outline: none;
    flex-grow: 1;
    font-family: ${(props) => props.theme.typography.fontFamily.regular};
    font-size: ${(props) => props.theme.typography.paragraph14.size};
    color: ${(props) => props.theme.colour.text.black};
    &::placeholder {
        color: ${(props) => props.theme.colour.text.grey};
    }
    &.hide {
        display: none;
    }
    @media (min-width: ${(props) => props.theme.breakpoints.s}) {
        font-size: ${(props) => props.theme.typography.paragraph18.size};
    }
`
const IconWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 36px;
    height: 36px;
    border-radius: 20px;
    font-size: 16px;
    color: ${(props) => props.theme.colour.text.white};
    background-color: ${(props) => props.theme.colour.button.blue};
    @media (min-width: ${(props) => props.theme.breakpoints.m}) {
        width: 40px;
        height: 40px;
        font-size: 18px;
    }
    &:hover {
        transition: background-color 0.2s;
        background-color: #0464f5;
    }
    &.openSearch {
        background-color: ${(props) => props.theme.colour.cardBackground};
        color: ${(props) => props.theme.colour.text.grey};
    }
`

export const SearchBar = (props: any) => {
    const [isOpen, setIsOpen] = useState(false)
    const inputRef = useRef<any>()

    // Changes the state of the isOpen state and resets the search input values to an empty string
    const useSearch = () => {
        setIsOpen(!isOpen)

        if (isOpen === true) {
            props.search('')
            Array.from(document.querySelectorAll('input')).forEach((input) => (input.value = ''))
        }
    }

    // Function that focuses the SearchInput when the search bar is visible
    useEffect(() => {
        if (isOpen) {
            inputRef.current.focus()
        }
    }, [isOpen])

    return (
        <SearchContainer className={isOpen ? 'open' : ''}>
            <SearchWrapper>
                <SearchInput ref={inputRef} type='text' className={isOpen ? '' : 'hide'} placeholder='Search...' onChange={(event) => props.search(event.target.value)}></SearchInput>
                <IconWrapper onClick={useSearch} className={isOpen ? 'openSearch' : ''}>
                    <FontAwesomeIcon icon={isOpen ? faXmark : faMagnifyingGlass} />
                </IconWrapper>
            </SearchWrapper>
        </SearchContainer>
    )
}
