import styled from '@emotion/styled'
import { useEffect, useRef, useState } from 'react'
import { Paragraph14 } from '../typography'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons'

const ToolWrapper = styled.div`
    position: relative;
    display: inline-block;
`
const IconWrapper = styled.div`
    position: relative;
    display: flex;
    width: 24px;
    height: 24px;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: ${(props) => props.theme.colour.slate};
    border: 2px solid ${(props) => props.theme.colour.cardBackground};
    transition: all 0.1s;
    &.activeRing {
        border: 2px solid ${(props) => props.theme.colour.slate};
        border-radius: 100px;
    }
    &: hover {
        cursor: pointer;
    }
`

const ToolItem = styled.div`
    position: absolute;
    width: 240px;
    display: flex;
    align-self: stretch;
    justify-content: center;
    background-color: ${(props) => props.theme.colour.slate};
    border-radius: 5px;
    // box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
    color: white;
    text-align: center;
    transition: all 0.3s;
    &.down {
        margin-bottom: 20px;
        bottom: 100%;
        right: 50%;
        padding: 4px;
        transform: translateX(50%);
        &::after {
            position: absolute;
            content: '';
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 12px solid ${(props) => props.theme.colour.slate};
            // filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.2));
            bottom: -12px;
            left: 112px;
        }
    }
`

export const Tooltip = (props: any) => {
    const [isClicked, setIsClicked] = useState(false)
    const clickRef = useRef<any>()

    // Effect that closes the tool tip if there is a click outside of component
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (clickRef.current && !clickRef.current.contains(event.target)) {
                setIsClicked(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [clickRef])

    return (
        <ToolWrapper>
            <IconWrapper ref={clickRef} className={isClicked ? 'activeRing' : ''} onClick={() => setIsClicked(!isClicked)}>
                <FontAwesomeIcon icon={faCircleQuestion} />
            </IconWrapper>
            {isClicked && (
                <ToolItem className='down'>
                    <Paragraph14>{props.message}</Paragraph14>
                </ToolItem>
            )}
        </ToolWrapper>
    )
}
