import styled from '@emotion/styled'
import { useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { Paragraph14 } from '../typography'
import { useLocation } from 'react-router-dom'

/* Wrapper for the resource card */
const ResourceWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    box-shadow: 0px 4px 6px rgba(65, 76, 78, 0.04);
    border-radius: 1px;
    background-color: ${(props) => props.theme.colour.cardBackground};
    max-height: 88px;
    transition: all 0.3s;
`

/** Header tags */

/* Wrapper that contains the header elements (Always displayed) */
const HeadlineWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px;
    align-self: stretch;
    gap: 8px;
`
/* Image that displays a resource logo */
const StyledImg = styled.img`
    width: 56px;
    height: 56px;
    object-fit: contain;
`
/* Div that contains the chevron icon */
const ChevronWrapper = styled.div`
    width: 24px;
    height: 24px;
    cursor: pointer;
`
/* A div tag that contains the ResourceName */
const LinkWrapper = styled.div`
    align-items: center;
    justify-content: center;
    text-align: center;
    text-decoration: none;
    width: fit-content;
`
/* Styled <a> tag that is underlined on hover */
const ResourceName = styled.a`
    text-decoration: none;
    font-family: ${(props) => props.theme.typography.fontFamily.regular};
    font-size: ${(props) => props.theme.typography.paragraph18.size};
    color: ${(props) => props.theme.colour.black};
    &:hover {
        text-decoration: underline;
    }
`

/** Content Tags */

/* Wrapper that contains all the card content. Visible only when the active class is used */
const ContentWrapper = styled.div`
    align-self: stretch;
    justify-self: stretch;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    max-height: 0;
    transition: all 0.3s;
    &.active {
        margin-top: 16px;
        gap: 16px;
        max-height: 800px;
    }
`
/* Divider between header and content */
const StyledHr = styled.hr`
    border: 0;
    height: 1px;
    background: ${(props) => props.theme.colour.text.grey};
    margin-bottom: 16px;
`

/**
 * Resource Card:
 * Takes a logo image, a link to a resource website, a resource name, and a resource description as props
 * Displays/hides the card content when the chevron is clicked
 */
export const ResourceCard = (props: any) => {
    // State that keeps track of the chevron being clicked
    const [isActive, setIsActive] = useState(false)
    const [contentHeight, setHeight] = useState('88px')
    const content = useRef<any>(null)

    // Closes all open cards on page change
    const pathname = useLocation()
    useEffect(() => {
        setIsActive(false)
    }, [pathname])

    const toggleCard = () => {
        setIsActive(!isActive)
        setHeight(isActive ? '88px' : `${content.current.scrollHeight + 104}px`)
    }

    return (
        <ResourceWrapper onClick={() => toggleCard()} style={{ maxHeight: `${contentHeight}` }}>
            {/* Headline (Always displayed) */}
            <HeadlineWrapper>
                <StyledImg src={props.logo} />
                <LinkWrapper>
                    <ResourceName href={props.website} target='_blank'>
                        {props.resourceName}
                    </ResourceName>
                </LinkWrapper>
                <ChevronWrapper>
                    <FontAwesomeIcon icon={isActive ? faChevronUp : faChevronDown} />
                </ChevronWrapper>
            </HeadlineWrapper>
            {/* Content (Displayed or hidden when chevron is clicked) */}
            <ContentWrapper ref={content} className={isActive ? 'active' : ''}>
                <StyledHr />
                <Paragraph14>{props.description}</Paragraph14>
            </ContentWrapper>
        </ResourceWrapper>
    )
}
